import { Typography } from "@mui/material";
import { styled as styling } from "@mui/material/styles";
import Color from "../../assets/colors";

export const NavbarContainer = styling("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  backgroundColor: Color.white,
  boxShadow: "0px 2px 18px 2px rgba(0,0,0,0.25)",

  height: "64px",
  width: "100%",
  padding: "0 64px",

  position: "fixed",
  top: 0,

  zIndex: 1000,

  "@media (max-width: 1024px)": {
    justifyContent: "center",
  },
});

export const FooterContainer = styling("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  backgroundColor: Color.white,
  boxShadow: "0px 2px 2px 2px rgba(0,0,0,0.25)",

  height: "40px",
  width: "100%",
  padding: "0 64px",

  position: "fixed",
  bottom: 0,

  zIndex: 1000,
});

export const PageContainer = styling("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1rem",
  justifyContent: "center",

  textAlign: "center",
  width: "100%",
  height: "100svh",
  backgroundColor: Color.hiredlyPurple,

  "@media (max-width: 640px)": {
    // padding: "0 1rem",
  },
});

export const TextContainer = styling("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export const TextStyled = styling(Typography)({});

export const ImageStyled = styling("img")({});

export const PurpleText = styling("span")({
  color: Color.hiredlyPurple,
  marginLeft: "0.25rem",
});

export const BoldText = styling("span")({
  fontWeight: "700",
  marginLeft: "0.25rem",
});
